/* latin */
@font-face {
    font-family: "Noticia Text";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Noticia_Text/noticiatext-regular-webfont.woff2")
            format("woff2"),
        url("/fonts/Noticia_Text/noticiatext-regular-webfont.woff")
            format("woff"),
        url("/fonts/Noticia_Text/NoticiaText-Regular.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Noticia Text";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/fonts/Noticia_Text/noticiatext-bold-webfont.woff2")
            format("woff2"),
        url("/fonts/Noticia_Text/noticiatext-bold-webfont.woff")
            format("woff"),
        url("/fonts/Noticia_Text/NoticiaText-Bold.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url("/fonts/Open_Sans/opensans-italic-webfont-webfont.woff2")
            format("woff2"),
        url("/fonts/Open_Sans/opensans-italic-webfont-webfont.woff")
            format("woff"),
        url("/fonts/Open_Sans/OpenSans-Italic.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url("/fonts/Open_Sans/opensans-regular-webfont-webfont.woff2")
            format("woff2"),
        url("/fonts/Open_Sans/opensans-regular-webfont-webfont.woff")
            format("woff"),
        url("/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url("/fonts/Open_Sans/opensans-semibold-webfont-webfont.woff2")
            format("woff2"),
        url("/fonts/Open_Sans/opensans-semibold-webfont-webfont.woff")
            format("woff"),
        url("/fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url("/fonts/Open_Sans/opensans-bold-webfont-webfont.woff2")
            format("woff2"),
        url("/fonts/Open_Sans/opensans-bold-webfont-webfont.woff")
            format("woff"),
        url("/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-stretch: 100%;
    font-display: swap;
    src: url("/fonts/Open_Sans/opensans-extrabold-webfont-webfont.woff2")
            format("woff2"),
        url("/fonts/Open_Sans/opensans-extrabold-webfont-webfont.woff")
            format("woff"),
        url("/fonts/Open_Sans/OpenSans-ExtraBold.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

html,
body {
    padding: 0;
    margin: 0;
}

html body {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Noticia Text", serif;
}


/* TODO: Find a better solution than global css */
.ProseMirror.remirror-editor {
    overflow-y: inherit !important;
    min-height: 40vh;
    outline: none;
}

[data-cy="testFloatingLinkToolbar"] {
    z-index: 1001;
}

button.MuiButtonBase-root.MuiToggleButton-root {
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 4px;
    background: #11273a;
    color: #fff;
}

